import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';


// Criar o contexto
const AuthContext = createContext();

// Componente de provedor do contexto
export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [logged, setLogged] = useState(false);
  const [loading, setLoading] = useState(true);
  const loadingMessages = [
    "Carregando, por favor aguarde...",
    "Estamos preparando tudo para você...",
    "Quase lá, não desista!",
    "Um momento, estamos finalizando...",
  ];
  const [currentMessage, setCurrentMessage] = useState(loadingMessages[0]);
  const [messageIndex, setMessageIndex] = useState(0);

  useEffect(() => {
    const fetchTokenFromServer = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL_LINK}/get-token`, {
          withCredentials: true,
        });
        const { token } = response.data;
        setToken(token);
        setLogged(true);
      } catch (error) {
        console.error('Erro ao recuperar o token:', error);
      } finally {
        setLoading(false); // Desativar o indicador de carregamento
      }
    };

    fetchTokenFromServer();
  }, []);

  useEffect(() => {
    if (loading) {
      const intervalId = setInterval(() => {
        setMessageIndex((prevIndex) => (prevIndex + 1) % loadingMessages.length);
        setCurrentMessage(loadingMessages[messageIndex]);
      }, 6000); // Troca a mensagem a cada 6 segundos

      return () => clearInterval(intervalId); // Limpa o intervalo ao desmontar
    }
  }, [loading, messageIndex]); // Dependências incluem loading e messageIndex

  if (loading) {
    return (
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '15px',
          color: '#333',
          textAlign: 'center',
          zIndex: '9999',
        }}
      >
        {currentMessage}
      </div>
    ); // Exibe a mensagem atual enquanto carrega
  }
  

  return (
    <AuthContext.Provider value={{ logged, setLogged, token, setToken }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook personalizado para usar o contexto de autenticação
export const useAuth = () => useContext(AuthContext);
