import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PhotoCard from '../components/PhotoCard';
import { useParams } from 'react-router-dom';
import { Modal, Carousel } from 'react-bootstrap'; 
import styles from '../styles/CategoriaPage.module.css'; 
import Footer from '../components/Footer';


function CategoriaPage() {
  const { categoria } = useParams();
  const [fotos, setFotos] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  useEffect(() => {
    const fetchFotos = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL_LINK}/active-photos`);
        const categoriaFotos = response.data.find(cat => cat.fotografia_categoria === categoria);

        if (categoriaFotos) {
          const fotoIds = categoriaFotos.fotos_por_categoria;
          const fotosData = await Promise.all(fotoIds.map(id => axios.get(`${process.env.REACT_APP_API_URL_LINK}/photosid/${id}`)))
            .then(responses => responses.map(res => res.data));
          setFotos(fotosData);
        }
      } catch (error) {
        console.error('Erro ao buscar fotos da categoria: ', error);
      }
    };

    fetchFotos();
  }, [categoria]);

  const openModal = (index) => {
    setSelectedImageIndex(index);
    setShowModal(true);
  };


  return (
    <div className={styles.bodyPage}>
<a className={styles.textWelcome} href='/acervo-fotografico'>ACERVO FOTOGRÁFICO &gt; {categoria}</a>

      <div className={styles.container}>
        {fotos.map((foto, index) => (
          // <div key={foto.id} className={styles['photo-card']} onClick={() => openModal(index)}>
          <div key={foto.id} className={styles['photo-card']} >
            <PhotoCard foto={foto} />
          </div>
        ))}
      </div>


      <Footer />
    </div>
  );
}


export default CategoriaPage;
