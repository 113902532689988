import React, { useState, useEffect } from "react";
import axios from "axios";
import { SiAdobeacrobatreader } from "react-icons/si";
import NavBar from "../components/Navbar";
import Footer from "../components/Footer";
import styles from "../styles/ListaBibliografica.module.css";
import Select from "react-select";

export default function ListaBibliografica() {
    const [books, setBooks] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedYear, setSelectedYear] = useState("");
    const [selectedAuthor, setSelectedAuthor] = useState("");
    const [selectedPublishType, setSelectedPublishType] = useState("");
    const [selectedArea, setSelectedArea] = useState("");
    const [isAuthorIny, setIsAuthorIny] = useState(false);
    const [selectedSortField, setSelectedSortField] = useState("author");
    const [sortDirection, setSortDirection] = useState("asc");
    const [isVisible, setIsVisible] = useState(false);


    const getBooks = async () => {
        try {
            // const res = await axios.get(`${process.env.REACT_APP_API_URL_LINK}/active-books`);
            const res = await axios.get(`${process.env.REACT_APP_API_URL_LINK}/active-books`);
            // console.log(JSON.stringify(res.data));
            setBooks(res.data.sort((a, b) => a.autor_sobrenome.localeCompare(b.autor_sobrenome)));
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getBooks();
    }, []);

    const filteredBooks = books.filter((book) => {
        const searchData = Object.values(book).join(" ").toLowerCase();
        const searchTermLower = searchTerm.toLowerCase();

        return (
            searchData.includes(searchTermLower) &&
            (!selectedYear || book.publicacao_ano === selectedYear.value) &&
            (!selectedAuthor || book.autor_nome.includes(selectedAuthor.value) || book.autor_sobrenome.includes(selectedAuthor.value)) &&
            (!selectedPublishType || book.publicacao_tipo.toLowerCase() === selectedPublishType.value.toLowerCase()) &&
            (!selectedArea || book.publicacao_area_conhecim.toLowerCase() === selectedArea.value.toLowerCase()) &&
            (isAuthorIny ? book.publicacao_autor_naoiny === 1 : true)
        );
    });

    const toggleClearFilters = () => {
        setIsAuthorIny('');
        setSelectedArea('');
        setSelectedPublishType('');
        setSelectedYear('');
        setSearchTerm('');
    };

    const toggleFilters = () => {
        setIsVisible(!isVisible);
    };


    const years = Array.from(new Set(books.map((book) => book.publicacao_ano)))
        .sort((a, b) => parseInt(b.substring(0, 4)) - parseInt(a.substring(0, 4)))
        .map((year) => ({ value: year, label: year }));
    const publishType = Array.from(new Set(books.map((book) => book.publicacao_tipo))).sort().map((publishType) => ({ value: publishType, label: publishType }));
    const areas = Array.from(new Set(books.map((book) => book.publicacao_area_conhecim))).sort().map((area) => ({ value: area, label: area }));

    const sortOptions = [
        { value: 'author', label: 'Autor' },
        { value: 'year', label: 'Ano de Publicação' },
        { value: 'publishType', label: 'Tipo de publicação' },
        // Adicione outras opções de classificação aqui, se necessário
    ];

    const directionOptions = [
        { value: 'asc', label: 'A-Z' },
        { value: 'desc', label: 'Z-A' },
    ];

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid #d3d3d3',
            width: '100%',
            // minWidth: '100%',
            maxWidth: '100%',
            textAlign: 'start',
            fontSize: '0.9em',
            cursor: 'pointer',
            backgroundColor: state.isSelected ? '#990000' : '#f9f9f9',
        }),

        dropdownIndicator: (provided, state) => ({
            ...provided,
            color: '#990000',
            textAlign: 'start',
            fontSize: '0.9em',
        }),

        singleValue: (provided, state) => ({
            ...provided,
            color: state.isSelected ? '#990000' : 'black',
            backgroundColor: state.isSelected ? '#f9f9f9' : '#f9f9f9',
            textAlign: 'start',
            fontSize: '0.9em',
        }),

        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#f9f9f9' : 'white',
            color: state.isSelected ? '#990000' : 'black',
            textAlign: 'start',
            fontSize: '0.9em',
            '&:hover': {
                color: 'black',
                backgroundColor: '#f2f2f2',
                textAlign: 'start',
                fontSize: '0.9em',
            },
        }),
    };

    const customTheme = (theme) => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary: '#f2f2f2',

        },
    });

    const handleSortChange = (selectedOption) => {
        setSelectedSortField(selectedOption.value);
        sortBooks(selectedOption.value, sortDirection);
    };

    const handleDirectionChange = (selectedOption) => {
        setSortDirection(selectedOption.value);
        sortBooks(selectedSortField, selectedOption.value);
    };

    const sortBooks = (field, direction) => {
        let sortedBooks = [...books];
        switch (field) {
            case 'author':
                sortedBooks = [...sortedBooks.sort((a, b) => (a.autor_sobrenome > b.autor_sobrenome ? 1 : -1))];
                break;
            case 'year':
                sortedBooks = [...sortedBooks.sort((a, b) => (a.publicacao_ano > b.publicacao_ano ? 1 : -1))];
                break;
            case 'publishType':
                sortedBooks = [...sortedBooks.sort((a, b) => (a.publicacao_tipo > b.publicacao_tipo ? 1 : -1))];
                break;
            default:
                break;
        }
        if (direction === 'desc') {
            sortedBooks.reverse();
        }
        setBooks(sortedBooks);
    };

    return (
        <div className={styles.body}>
            <div className={styles.bodyListagem}>

                <div className={styles.lineTitleAndButton}>
                    <h3 className={styles.titlePage}>Lista de bibliografia</h3>
                    <button className={styles.buttonFilter} onClick={toggleFilters}>
                        {isVisible ? 'Ocultar pesquisa' : 'Pesquisar'}
                    </button>
                </div>

                {isVisible && (<div className={styles.lineFilter}>
                    <div className={styles.inputSearchDiv}>
                        <input
                            className={styles.inputSearch}
                            type="text"
                            placeholder="Digite aqui para buscar"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div className={styles.containerFilter}>
                        <div>
                            <Select
                                value={directionOptions.find(option => option.value === sortDirection)}
                                onChange={handleDirectionChange}
                                options={directionOptions}
                                placeholder="Direção"
                                theme={customTheme}
                                styles={customStyles}
                                isSearchable={false}
                            />
                        </div>
                        <div>
                            <Select
                                value={selectedYear}
                                onChange={(selectedOption) => setSelectedYear(selectedOption)}
                                options={years}
                                placeholder="Ano"
                                styles={customStyles}
                                theme={customTheme}
                                isClearable={true}
                                isSearchable={false}
                            />
                        </div>
                        <div>
                            <Select
                                value={selectedPublishType}
                                onChange={(selectedOption) => setSelectedPublishType(selectedOption)}
                                options={publishType}
                                placeholder="Tipo"
                                styles={customStyles}
                                theme={customTheme}
                                isClearable={true}
                                isSearchable={false}
                            />
                        </div>
                        <div>
                            <Select
                                value={selectedArea}
                                onChange={(selectedOption) => setSelectedArea(selectedOption)}
                                options={areas}
                                placeholder="Área"
                                styles={customStyles}
                                theme={customTheme}
                                isClearable={true}
                                isSearchable={false}
                            />
                        </div>
                        <div>
                            <div>
                                <label style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="checkbox"
                                        checked={isAuthorIny}
                                        onChange={() => setIsAuthorIny(!isAuthorIny)}
                                    />
                                    <span style={{ marginLeft: '8px', cursor: 'pointer' }}>Autores Iny</span>
                                </label>
                            </div>
                        </div>
                        <button onClick={toggleClearFilters} className={styles.buttonClearFilter}>Limpar filtros</button>
                    </div>
                </div>)}
                {filteredBooks.length === 0 ? (
                    <p style={{ marginBottom: '600px' }}>Sem correspondências no momento...</p>
                ) : (
                    filteredBooks.map((item, i) => (
                        <div className={styles.listContainer} key={i}>
                            <div className={styles.headerList}>
                                <p className={styles.autorArea}>{item.autor_sobrenome}, {item.autor_nome}. {item.publicacao_ano}.</p>
                                {item.publicacao_url_pdf && (
                                    <div className={styles.adobeIcon}>
                                        <a style={{ color: "inherit", textDecoration: "none", margin: 0, justifyContent: 'center' }} rel="noopener" href={item.publicacao_url_pdf} target="_blank"><SiAdobeacrobatreader /></a>
                                    </div>
                                )}
                            </div>
                            <p className={styles.descriptionArea}>{item.publicacao_titulo}. {item.publicacao_complemento}.</p>
                        </div>
                    ))
                )}
            </div>
            <Footer />
        </div>
    );
}
